<script setup lang="ts">
useMetaDefaultHead()
useSeoMetaLinksAlternate()
</script>

<template>
  <div>
    <slot />
  </div>
</template>
